import CarouselPage from '../component/carousel/Carousel'
import doge from '../Images/carousel/vitc2.jpg'
import './pages.css'
import Gulfood from '../component/gulfood/Gulfood'
import CardList from '../component/Card/CardList'

const HomePage = () => { 
  return (
    <section className="homePage"> 
      <div >
        <CarouselPage /> 
      </div>
      <hr></hr>
      <div className='card-container'>
        <CardList />
      </div>
      <div className='section3Img container'>
       <p className='vitaminCtitle' >DOGE vitamin C</p>
        <img src={doge} alt='doge' />
      </div>
       <div className="gulfood mt-5">
        <Gulfood /><hr/>
      </div>
    </section>
  )
}

export default HomePage;
