import React from "react";
import Toolbar from "./Toolbar";
import './toolBar.css'
import SideDrawer from "./sidemenu/sideDrawer";
import BackDrop from './backDrop/backDrop'

class Navbar extends React.Component {
  state = {
    sideDrawerOpen: false
  };

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen };
    });
  };
  backDropClickHandler = () => {
    this.setState({ sideDrawerOpen: false });
  };

  render() {
    // let sideDrawer;
    let backdrop;

    if (this.state.sideDrawerOpen) {
      // sideDrawer = <SideDrawer />;
      backdrop = <BackDrop click={this.backDropClickHandler} />;
    }
    return (
      <div style={{ height: "100%" }}>
        <Toolbar drawerToggleClickHandler={this.drawerToggleClickHandler} />
        <SideDrawer show={this.state.sideDrawerOpen} />
        {backdrop} 

        <main style={{ marginTop: "64px" }}>
          <p className="doge-group">DOGE <p>food stuff LLC</p> </p>
        </main>
      </div>
    );
  }
}

export default Navbar;
