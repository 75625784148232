import './products.css'
import nut1 from '../../Images/nuts/nuts1.jpg'
import nut2 from '../../Images/nuts/nuts2.jpg'
import nut3 from '../../Images/nuts/nuts3.jpg'
import roar from '../../Images/roar.jpg'
import roar24x from '../../Images/roar24x.jpg'
import bazooka from '../../Images/bazooka.jpg'
import doge from '../../Images/carousel/vitc2.jpg'

const Products = () => { 
  return (
    <div className="container">
      <div className="row"> 
        <div className="col-12 title">
          {' '}
          High quality of nuts available in dubai
        </div>
        <div className='row'>

        <img className="col-12 col-md-4 " src={nut1} alt="nuts" />
        <img className="col-12 col-md-4 " src={nut2} alt="nuts" />
        <img className="col-12 col-md-4 " src={nut3} alt="nuts" />
        </div><br/><hr></hr>
        <div className='row'>
        <div className="col-12 title">
          <hr/>
          Energy drinks
        </div>
        <img className="col-12 col-md-4 " src={doge} alt="doge" />
        <img className="col-12 col-md-4 " src={roar} alt="roar" />
        <img className="col-12 col-md-4 " src={roar24x} alt="roar24x" />
        <img className="col-12 col-md-4 " src={bazooka} alt="bazooka" />
        
        </div>
      </div>
     
    </div>
  )
}

export default Products
