import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Layout from './component/Layout/Layout'
import { Container } from 'react-bootstrap'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <>
    <div className='main-container '>
      <BrowserRouter>
        <Layout>
        
          <App />

        </Layout>
      </BrowserRouter>
    </div>
  </>,
)
