import roar24Banner from '../../Images/carousel/roar24Banner.jpg'
import bazookaBanner from '../../Images/carousel/bazookaBanner.jpg'
import roarBanner from '../../Images/carousel/roarBanner.jpg'
import './carousel.css'
import 'bootstrap/dist/css/bootstrap.min.css'

import Carousel from 'react-bootstrap/Carousel'
;<link 
  rel="stylesheet"
  href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css"
  integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65"
  crossorigin="anonymous"
/>

function CarouselPage() {
  return (
    <div className="carouselContainer">
      <Carousel fade interval={3000} className="carousel">
        <Carousel.Item className="carouselItem">
          <img
            className="d-block w-100 img"
            src={roar24Banner}
            alt="First slide"
          />
          <Carousel.Caption className="carousel-caption">
            <h3>Roar 24 x Energy Drink </h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
          <img
            className="d-block w-100 img"
            src={bazookaBanner}
            alt="Second slide"
          />
          
            <Carousel.Caption>
              <h3>Bazooka Energy Drink</h3>
            </Carousel.Caption>
          
        </Carousel.Item>
        <Carousel.Item className="carouselItem">
          <img
            className="d-block w-100 img"
            src={bazookaBanner}
            alt="Second slide"
          />

          <Carousel.Caption>
            <h3>Bazooka Energy Drink</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  )
}

export default CarouselPage
