import React from 'react'
import SideMenu from './sidemenu/SideMenu'
import './toolBar.css'
import { Link } from 'react-router-dom'
import '../Layout/Layout.css'
import logo from '../../Images/logo.jpg'

const toolbar = (props) => (
  <header className="toolbar">
    <nav className="toolbar_navigator">
      <div />
      <div className="toggle-btn">
        <SideMenu click={props.drawerToggleClickHandler} />
      </div>

      <div className="spacer" />
      <div className="toolbar_navigation-items">
        <div className="navigate">
          <div className="toolbar_logo">
            <a href="/">
              {' '}
              <img src={logo} />
            </a>
          </div>

          <div className="menu">
            <ul> 
              <li>
                <Link className="link" to="/">
                  Home
                </Link>
              </li>
              <li>
                <Link to="/products" className="link">
                  Products
                </Link>
              </li>
              <li>
                <Link to="/aboutUs" className="link">
                  {' '}
                  About Us
                </Link>
              </li>
              <li>
                <Link to="/contactUs" className="link">
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  </header>
)

export default toolbar
