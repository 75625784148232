
import { Switch , Route } from 'react-router-dom';
import './App.css';
import routes from './pages/routes'
function App() { 
  return (
    <div className='App  '>
      <Switch>
        <div >
        {routes.map((x)=>{
         return <Route {...x}/>
        })}
        </div>
       
      </Switch>
    </div>
  )
}

export default App;
