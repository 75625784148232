import './ContactUs.css'
import { BiMailSend, BiPhoneCall } from 'react-icons/bi'
import { FaMapMarkerAlt } from 'react-icons/fa'
const ContactUs = () => {
  return (
    <>
      <div className="container">
        <div className="row firstRow">Feel free to Contact Us</div>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4 contactCard">
            <p className="contactsIcon">
              {' '}
              <BiMailSend className="d-none d-md-block" />
            </p>{' '}
            Email:{' '}
            <a href="mailto:doge-group@hotmail.com">doge-group@hotmail.com</a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 contactCard">
            <p className="contactsIcon">
              <BiPhoneCall className="d-none d-md-block" />{' '}
            </p>
            Contact Number : &nbsp;
            <a href="tel:+971568396000">&nbsp;+971568396000</a>
          </div>
          <div className="col-12 col-md-6 col-lg-4 contactCard">
            <p className="contactsIcon">
              {' '}
              <FaMapMarkerAlt className="d-none d-md-block" />{' '}
            </p>{' '}
            locations
            <br /> 
            <span className="address"> branch 1:</span> <br/> above Day to Day HyperMarket , AlFuttaim Tower , baniyas. Deira, Dubai
             
            <br />
            <span className="address"> branch 2:</span><br/> Metro Station,
            shop 40.4 , Baniyas ,Dubai
            <br />
            <span className="address"> branch 3:</span><br/> Shop 2 , Port Saeed ,Dubai
            <br />
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs;
