import './Layout.css';
const Footer = () => {
  return (
    <footer>
      <span className='top'> <a href='#top' >&#8593;</a> </span>
      <div className="black">
        contact Us :<br />
        <a href="tel:+971568396000">&nbsp;+971568396000</a>
        <br></br>
      </div>
    <div className='red'>
        
          <a href="mailto:doge-group@hotmail.com">Email: doge-group@hotmail.com</a>
        </div>
      <br></br>
    <div className='yellow'>

      Dubai, United Arab Emirates
    </div>
    </footer>
  )
}

export default Footer
