import gulfood from '../../Images/gulfood/gulfood.jpg'
import gulfoodGroup from '../../Images/gulfood/group.jpg'
import './gulfood.css'
const Gulfood = () => {
    return (  <div className="gulfood">
    <div className="row">
      <div className="col-12 col-md-6 gulfoodTitle">
        <img className=" gulfoodImg" src={gulfood} alt="gulfood" />
        <p className=" fs-5">
          {' '}
          <span className="textBold"> Come And Visit Us in Dubai</span>
          <div className='gulfoodText' >
            Gulfood is one of the largest annual food and beverage trade
            exhibitions in the world,
            <br /> where exhibitors come from all continents.
            <br />
            As <span className="textBold">Doge Group</span> it is crucial
            for us to be present during every edition. It is a very
            convenient place for us to meet our existing & future partners
            to move forward on ongoing deals and agreements as well as the
            best platform to highlight our latest innovations in products &
            packaging. Moreover, it is a very attractive opportunity for
            export and import where we can meet some international suppliers
            & buyers, especially from All Over the World. Gulfood
            participation is a very strong opportunity for us to support our
            strategic objectives since we are prioritizing Middle East, Europe , USA
            markets for our expansion while the exhibition
            sees a great focus on those Markets during the event
            compared with other international food exhibitions.
          </div>
        </p>
      </div>
      <img className="col-12 col-md-6  managerImg" src={gulfoodGroup} alt="gulfood"/>
    </div>
  </div> );
}
 
export default Gulfood;