
import Bazooka from './EnergyDrinks/Bazooka'
import Roar from './EnergyDrinks/Roar'
import Roar24x from './EnergyDrinks/Roar24x'
import ContactUs from './contactUs/ContactUs'
import HomePage from './HomePage'
import Products from '../component/products/Products'
import AboutUs from './aboutUs/AboutUs'
const routes = [
  { path: '/', component: HomePage, exact: true },
  { path: '/aboutUs', component: AboutUs },
  { path: '/contactUs', component: ContactUs },
  { path: '/bazooka', component: Bazooka },
  { path: '/roar', component: Roar },
  { path: '/roar24x', component: Roar24x },
  {path:'/products' , component: Products}
]
export default routes;
