import './EnergyDrinkAboutPage.css'
import img1 from '../../Images/roar.jpg'
import img2 from '../../Images/r1.jpg'
import map from '../../Images/bazooka/mapBazooka.jpg'
const Roar = () => {
  return (
    <div className="container">
      <div className="row">
        <img className="col-12 col-md-6 " src={img1} alt="bazooka" />

        <div className="col-12 col-md-6 textBazooka">
          <p>

          A beverage that typically contains a amounts of caffeine, added
          sugars, other additives, and legal stimulants such as guarana,
          taurine, and L-carnitine. These legal stimulants can increase
          alertness, attention, energy, as well as increase blood pressure,
          heart rate, and breathing.
          </p>
        </div>
      </div>
      <div className="row mt-5">
        <div className="col-12 col-md-6 textBazooka">
          <p>

          A beverage that typically contains a amounts of caffeine, added
          sugars, other additives, and legal stimulants such as guarana,
          taurine, and L-carnitine. These legal stimulants can increase
          alertness, attention, energy, as well as increase blood pressure,
          heart rate, and breathing.
          </p>
        </div>
        <img className="col-12 col-md-6 " src={img2} alt="bazooka" />
      </div>
      <div className="row mt-5">
        <p className='col-12 titleMap'> Roar energy drink is distributing all the world wide... </p>
        <img className="col-12" src={map} alt="map" />
      </div>
    </div>
  )
}

export default Roar
