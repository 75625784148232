import './aboutUs.css'
import '../pages.css';
import office1 from '../../Images/office/dogeOffice.jpg'
const AboutUs = () => {
  return ( 
    <div className=" containerAboutUs">
     
      <div className="row"> 
        <img  className=" col-12 col-lg-6" src={office1} alt="office"/>
        <p className="textAboutUs  col-12 col-lg-6">
          <h1 className='anime'>Doge Group</h1>
          We are an international trading company Group is characterized by its
          customer and market proximity, and the high flexibility in the process
          planning of projects. We realize this through our experienced and high
          skilled team.
        </p>
      </div>
    </div>
  )
}

export default AboutUs
