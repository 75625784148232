import roar from '../../Images/roar.jpg'
import roar24x from '../../Images/roar24x.jpg'
import bazoka  from '../../Images/bazoka.jpg'
import { Link } from 'react-router-dom'
import Cardd from './Card' 
import './Card.css'

const CardList = () => {
  return (
    <div className="cardsHome ">
      <div className="row">
        <div className=" col-12 col-md-6 col-lg-4 ">
          <Link to="/bazooka" className='link'>
            <Cardd 
              srcImg={bazoka}
              title={'Bazooka Energy drink '}
              text={
                ' 250ml   made in germany , RedBull taste, available in & Germany &  USA & UAE & Iran '
              }
              
            />
          </Link>
        </div>

        <div className="  col-12 col-md-6 col-lg-4">
          <Link to="/roar" className='link'>
            <Cardd
              srcImg={roar}
              title={'Roar Energy drink '}
              text={
                '500ml , made in germany RedBull taste, available in & Germany & USA & UAE & Africa & Iran '
              }
            />
          </Link>
        </div>
        <div className="  col-12 col-md-6 col-lg-4">
          <Link to="/roar24x" className='link'>
            <Cardd
              srcImg={roar24x}
              title={'Roar24X Energy drink '}
              text={
                '330ml  made in germany RedBull taste, available in Germany & Africa '
              }
            />
          </Link>
        </div>
      </div>
    </div>
  )
}

export default CardList
