import Card from 'react-bootstrap/Card'
 
import './Card.css'
function Cardd({ srcImg, title, text }) {
  return (
    <div className="card">
      <Card style={{}}>
        <Card.Img variant="top" src={srcImg} className='cardImg' />
        <Card.Body>
          <div className="cardTitle">
            <Card.Title>{title}</Card.Title>
            <Card.Text className="textCard" >{text}</Card.Text>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}

export default Cardd;
