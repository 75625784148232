import React from "react";
import "./sideDrawer.css";
import { Link } from 'react-router-dom';
import '../../Layout/Layout.css';
const sideDrawer = props => {
  let drawerClasses = ["side-drawer"];

  if (props.show) {
    drawerClasses = ["side-drawer", "open"];
  } 
  return (
    <nav className={drawerClasses.join(" ")}>
      <div className="navigate">
        
        <div >
          <ul>
            <li>
              <Link className="link" to="/">
                Home
              </Link>
            </li>
            <li>
              <Link to="/products" className="link">
                Products
              </Link>
            </li>
            <li>
              <Link to="/aboutUs" className="link">
                {' '}
                About Us
              </Link>
            </li>
            <li>
              <Link to="/contactUs" className="link">
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
       
      </div>
    </nav>
  );
};
export default sideDrawer;
